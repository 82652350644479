
// import { useLockBodyScroll } from "@uidotdev/usehooks";
import { cn } from "@/lib/utils";
import loading from '@assets/process-animate.svg';
import { state } from "@state/store";
import { useSnapshot } from "valtio";

export default function GlobalLoader() {
    // useLockBodyScroll();
    const snap = useSnapshot(state);

    return <div className={cn("top-0 left-0 flex flex-col  justify-center items-center bg-primary/80 w-screen overflow-hidden min-h-screen", snap.processing ? "!z-[2147483647] fixed" : "z-[0] hidden")}>
        <img className="max-w-[250px]" src={loading} width={200} height={200} />
        {/* <Icon icon="svg-spinners:blocks-shuffle-3" className="text-cyan-600 animate-bounce" width={200} height={200} /> */}
        <span className="text-cyan-600 text-lg mt-4 font-semibold animate-pulse">{snap.processing_text}</span>
    </div>
}
